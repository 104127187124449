








































import { Vue, Component, Prop } from 'vue-property-decorator';
import { RetrospectiveSession, RetrospectiveTheme } from '../models';

const defaultTheme = { colors: { text: 'default', background: '#00000000' } };

@Component
export default class SessionsActions extends Vue {
  @Prop({ type: Object as () => RetrospectiveSession, required: true })
  readonly value!: RetrospectiveSession;

  @Prop({ type: Object as () => RetrospectiveTheme, default: () => defaultTheme })
  readonly theme!: RetrospectiveTheme;
}
