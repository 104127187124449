
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { RetrospectiveSession, User } from '../models';
import { retrospectiveService } from '../services';

@Component
export default class CloneSession extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  session: RetrospectiveSession | null = null;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  @Watch('id', { immediate: true })
  onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('session', retrospectiveService.getSessionRef(id));
  }

  @Watch('session')
  onSessionChange(session: RetrospectiveSession) {
    if (session) {
      document.title = session.title;
      this.$bus.$emit('loading-indicator', false);
      this.cloneSession(session);
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Retro', '/retro');
  }

  async cloneSession(session: RetrospectiveSession) {
    try {
      const newSession = await retrospectiveService.cloneSession(session, this.user);
      this.$bus.$emit('snackbar-notify', 'Retrospective successfully cloned', 'success');
      this.$analytics.logEvent('retro-clone', {
        retro_id: session.id,
        user_id: this.userId,
        new_retro_id: newSession.id,
      });
      this.$router.push({ name: 'retro' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
