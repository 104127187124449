import { getId, getPexelPhoto } from '@/utils';
import {
  RetrospectiveSession,
  RetrospectiveBoard,
  RetrospectiveCard,
  RetrospectiveSettings,
  RetrospectivePhases,
  RetrospectiveCardVotes,
  RetrospectiveParticipants,
  RetrospectiveThemes,
  User,
} from './models';

export const types = require('../../../admin/retro/types.json');

export const defaultType = types[0];

export const minColumnWidth = '300px';
export const maxCardTitleLength = 140;
export const maxUserVotes = 5;

export const defaultThemes: RetrospectiveThemes = {
  Clean: {
    id: 'Clean',
    title: 'Clean',
    description: 'Clean theme (default)',
    background: '#E8EAF6',
    colors: { text: 'black', highlight: 'blue white--text', background: '#FFFFFF' },
  },
  CleanDark: {
    id: 'CleanDark',
    title: 'Clean Dark',
    description: 'Clean dark theme',
    background: '#616161',
    colors: { text: 'white', highlight: 'amber black--text', background: '#1E1E1E' },
  },
  Retro: {
    id: 'Retro',
    title: 'Retro',
    description: 'Retro theme',
    background: null,
    image: getPexelPhoto(3183190),
    colors: { text: 'white', highlight: 'brown lighten-3 black--text', background: '#00000099' },
  },
  People: {
    id: 'People',
    title: 'People',
    description: 'People theme',
    background: null,
    image: getPexelPhoto(3184291),
    colors: { text: 'white', highlight: 'cyan lighten-3 black--text', background: '#000000AA' },
  },
  Teamwork: {
    id: 'Teamwork',
    title: 'Teamwork',
    description: 'Teamwork theme',
    background: null,
    image: getPexelPhoto(3184422),
    colors: { text: 'white', highlight: 'pink lighten-3 black--text', background: '#00000099' },
  },
  Office: {
    id: 'Office',
    title: 'Office',
    description: 'Office theme',
    background: null,
    image: getPexelPhoto(1181611),
    colors: { text: 'white', highlight: 'amber lighten-3 black--text', background: '#000000CC' },
  },
};

export const defaultSettings = (): RetrospectiveSettings => ({
  enableComments: false,
  enableActions: false,
  enableTags: false,
  enableVoting: false,
  enableEditing: false,
  enableMoving: false,
  enableGrouping: false,
  showAllCards: false,
  showAllVotes: false,
  maxVotes: maxUserVotes,
});

export const defaultBoard = (): RetrospectiveBoard => ({
  columnIds: [],
});

export const defaultRetrospective = (user: User): RetrospectiveSession => ({
  id: getId(),
  title: 'Retrospective',
  status: 'Draft',
  type: 'Random',
  theme: 'Retro',
  board: defaultBoard(),
  settings: defaultSettings(),
  filters: { participantId: null, cardId: null, tag: null },
  people: {} as RetrospectiveParticipants,
  events: [],
  phase: 0,
  order: 0,
  owner: user.id,
});

export const defaultCard = (title: string, user: User): RetrospectiveCard => ({
  id: getId(),
  title,
  createdBy: user,
  createdOn: Date.now(),
  votes: {} as RetrospectiveCardVotes,
  votesCount: 0,
  comments: [],
  actions: [],
  tags: [],
});

export const defaultColors = [
  { text: 'Default', value: 'default' },
  { text: 'Green', value: 'green' },
  { text: 'Yellow', value: 'yellow black--text' },
  { text: 'Red', value: 'red' },
  { text: 'Blue', value: 'blue' },
  { text: 'Pink', value: 'pink' },
  { text: 'Purple', value: 'purple' },
  { text: 'Indigo', value: 'indigo' },
  { text: 'Cyan', value: 'cyan' },
  { text: 'Teal', value: 'teal' },
  { text: 'Lime', value: 'lime' },
  { text: 'Amber', value: 'amber' },
  { text: 'Orange', value: 'orange' },
  { text: 'Brown', value: 'brown' },
  { text: 'Light blue', value: 'light-blue' },
  { text: 'Light green', value: 'light-green' },
  { text: 'Blue grey', value: 'blue-grey' },
  { text: 'Deep purple', value: 'deep-purple' },
  { text: 'Deep orange', value: 'deep-orange' },
  { text: 'Grey', value: 'grey' },
  { text: 'White', value: 'white black--text' },
  { text: 'Black', value: 'black white--text' },
];

export const defaultPhases: RetrospectivePhases = [
  { title: 'Start', description: 'Setting the scene' },
  { title: 'Reflect', description: 'Reflecting about the last period' },
  { title: 'Review', description: 'Collaboratively reviewing all shared reflections' },
  { title: 'Vote', description: 'Voting on the most important reflections' },
  { title: 'Discuss', description: 'Discussing all top-voted reflections' },
  { title: 'Finish', description: 'Closing the retrospective' },
];

export const defaultSortOrder = [
  { text: 'Default order', value: 0 },
  { text: 'Order by votes', value: 1 },
  { text: 'Order by time', value: 2 },
  { text: 'Order by creator', value: 3 },
];
