














import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { firestore } from '@/firebase';
import { getId } from '@/utils';
import { RetrospectiveSession, RetrospectiveType, User } from '../models';
import { retrospectiveService } from '../services';
import SessionBuilder from '../components/SessionBuilder.vue';

@Component({ components: { SessionBuilder } })
export default class EditSession extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  session: RetrospectiveSession | null = null;
  isOwner: boolean | null = null;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  @Watch('id', { immediate: true })
  onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('session', retrospectiveService.getSessionRef(id));
  }

  @Watch('session')
  onSessionChange(session: RetrospectiveSession) {
    if (session) {
      document.title = session.title;
      this.isOwner = session.owner === this.userId;
      this.$bus.$emit('loading-indicator', false);
      this.$analytics.logEvent('retro-edit', { retro_id: session.id, user_id: this.userId });
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Retro', '/retro');
    this.$bus.$emit('loading-indicator', true);
  }

  async saveSession(session: RetrospectiveSession, type: RetrospectiveType) {
    try {
      //if(session.type.columns === session.columns)
      // Update columns info
      for (let [cidx, column] of type.columns.entries()) {
        const { color, title, description } = column;
        let columnId = session.board.columnIds[cidx];
        if (!columnId) {
          columnId = getId();
          session.board.columnIds[cidx] = columnId;
          await retrospectiveService.createColumn(session.id, {
            id: columnId,
            color,
            title,
            description,
            cardIds: [],
          });
        } else {
          await retrospectiveService.updateColumn(session.id, columnId, { color, title, description });
        }
      }
      // Delete obsolete columns
      const obsoleteCols = session.board.columnIds.slice(type.columns.length);
      session.board.columnIds.length = type.columns.length;
      for (let columnId of obsoleteCols) {
        await retrospectiveService.getColumnsRef(session.id).doc(columnId).delete();
      }
      // Update session info
      session.updatedOn = Date.now();
      //session.board = await cloneBoard(session.id, type);
      await retrospectiveService.updateSession(session.id, session);
      this.$bus.$emit('snackbar-notify', 'Retrospective successfully saved', 'success');
      this.$analytics.logEvent('retro-save', { retro_id: session.id, user_id: this.userId });
      //this.$router.push(`/retro/${this.session.id}`);
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }

  async deleteSession(session: RetrospectiveSession) {
    try {
      await retrospectiveService.deleteSession(session.id);
      this.$bus.$emit('snackbar-notify', 'Retrospective successfully deleted', 'success');
      this.$router.push('/retro');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
