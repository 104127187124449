






































































































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import {
  RetrospectiveCardAction,
  RetrospectiveCardActions,
  RetrospectiveCard,
  RetrospectiveCards,
  RetrospectiveParticipant,
  RetrospectiveParticipants,
  RetrospectiveTheme,
  User,
} from '../models';

@Component
export default class Actions extends Vue {
  @PropSync('value', { type: Array as () => RetrospectiveCardActions, required: true })
  readonly actions!: RetrospectiveCardActions;

  @Prop({ type: Array as () => RetrospectiveCards, required: true })
  readonly cards!: RetrospectiveCards;

  @Prop({ type: Array as () => RetrospectiveParticipants, required: true })
  readonly participants!: RetrospectiveParticipants;

  @Prop({ type: Object as () => RetrospectiveTheme, required: true })
  readonly theme!: RetrospectiveTheme;

  @Prop({ type: Boolean, default: true })
  readonly readonly!: boolean;

  formatDate = formatDate;
  showDatePicker = false;

  get user(): User {
    return this.$store.getters['user/user'];
  }

  getCard(id: string): RetrospectiveCard | undefined {
    return this.cards.find((c) => c.id === id);
  }
  getDueDateColor(action: RetrospectiveCardAction) {
    if (!action.dueOn) return 'grey';
    if (action.completedOn) return 'success';
    if (action.dueOn < Date.now()) return 'error';
    return 'primary';
  }
  getAssigneeColor(action: RetrospectiveCardAction) {
    if (!action.assignedTo) return 'grey';
    return 'secondary';
  }

  toggleCheckbox(action: RetrospectiveCardAction) {
    //if (this.readonly) return;
    const card = this.getCard(action.cardId);
    if (!card) return;
    if (!action.completedOn) {
      action.completedOn = Date.now();
    } else {
      action.completedOn = null;
    }
    action.updatedOn = Date.now();
    //action.updatedBy = this.user;
    this.$bus.$emit('retro-action-update', card, action);
  }

  setAssignee(action: RetrospectiveCardAction, participant: RetrospectiveParticipant) {
    //if (this.readonly) return;
    const card = this.getCard(action.cardId);
    if (!card) return;
    action.assignedTo = participant;
    action.updatedOn = Date.now();
    //action.updatedBy = this.user;
    this.$bus.$emit('retro-action-update', card, action);
  }

  setDueDate(action: RetrospectiveCardAction, dueDate: string) {
    //if (this.readonly) return;
    if (!dueDate) return;
    const card = this.getCard(action.cardId);
    if (!card) return;
    action.dueOn = Date.parse(dueDate);
    action.updatedOn = Date.now();
    this.$bus.$emit('retro-action-update', card, action);
  }
}
