






















































































































































































































































































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import {
  RetrospectiveParticipant,
  RetrospectiveSettings,
  RetrospectiveColumn,
  RetrospectiveCard,
  RetrospectiveCardComment,
  RetrospectiveCardAction,
} from '../models';
import { maxCardTitleLength } from '../defaults';

@Component
export default class Card extends Vue {
  @PropSync('value', { type: Object as () => RetrospectiveCard, required: true })
  readonly card!: RetrospectiveCard;

  @Prop({ type: Object as () => RetrospectiveColumn, required: true })
  readonly column!: RetrospectiveColumn;

  @Prop({ type: Object as () => RetrospectiveSettings, required: true })
  readonly settings!: RetrospectiveSettings;

  @Prop({ type: Object as () => RetrospectiveParticipant, required: true })
  readonly user!: RetrospectiveParticipant;

  @Prop({ type: String, default: null })
  readonly color!: string;

  @Prop({ type: String, default: null })
  readonly owner!: string;

  @Prop({ type: Number, default: 0 })
  readonly phase!: number;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  @Prop({ type: Number, default: 0 })
  readonly userVotes!: number;

  maxCardTitleLength = maxCardTitleLength;
  formatDate = formatDate;
  editCard = false;
  showComments = false;
  showActions = false;
  showTags = false;
  commentText = null;
  actionText = null;
  tagText = null;

  get isOwner(): boolean {
    return this.owner === this.user.id;
  }
  get isAuthor(): boolean {
    return this.user.id === this.card.createdBy.id;
  }
  get votes(): number {
    if (!this.card.votes) return 0;
    if (this.isOwner || this.phase > 3 || this.settings.showAllVotes) return this.card.votesCount;
    return this.ownVotesCount;
  }
  get ownVotesCount(): number {
    if (!this.card.votes) return 0;
    return this.card.votes[this.user.id] || 0;
  }
  get commentsCount(): number {
    return Object.keys(this.card.comments!).length;
  }
  get actionsCount(): number {
    return Object.keys(this.card.actions!).length;
  }
  get tagsCount(): number {
    return this.card.tags!.length;
  }
  get showExtendedInfo(): boolean {
    return (
      this.settings.enableComments ||
      this.settings.enableActions ||
      this.settings.enableTags ||
      this.settings.enableVoting ||
      this.phase > 4
    );
  }

  // Card events
  updateCard(card: RetrospectiveCard) {
    this.editCard = false;
    card.updatedBy = this.user;
    card.updatedOn = Date.now();
    this.$bus.$emit('retro-card-update', card.id, card);
  }
  deleteCard() {
    this.$bus.$emit('retro-card-delete', this.card, this.column);
  }
  upvoteCard() {
    this.$bus.$emit('retro-card-upvote', this.card);
  }
  downvoteCard() {
    this.$bus.$emit('retro-card-downvote', this.card);
  }

  addComment(text: string) {
    if (!text) return;
    const comment: RetrospectiveCardComment = { title: text, createdBy: this.user, createdOn: Date.now() };
    this.commentText = null;
    this.$bus.$emit('retro-card-comment-create', this.card, comment);
  }
  deleteComment(comment: RetrospectiveCardComment) {
    this.$bus.$emit('retro-card-comment-delete', this.card, comment);
  }

  addAction(text: string) {
    if (!text) return;
    const action: RetrospectiveCardAction = {
      cardId: this.card.id,
      title: text,
      createdBy: this.user,
      createdOn: Date.now(),
      completedOn: null,
    };
    this.actionText = null;
    this.$bus.$emit('retro-card-action-create', this.card, action);
  }
  deleteAction(action: RetrospectiveCardAction) {
    this.$bus.$emit('retro-card-action-delete', this.card, action);
  }

  addTag(tag: string) {
    if (!tag) return;
    this.tagText = null;
    this.$bus.$emit('retro-card-tag-create', this.card, tag);
  }
  deleteTag(tag: string) {
    this.$bus.$emit('retro-card-tag-delete', this.card, tag);
  }
}
