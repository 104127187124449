






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { formatDuration } from '@/utils';

interface TimerValue {
  text: string;
  value: number | null;
}
type TimerValues = TimerValue[];

const defaultValues: TimerValues = [
  { text: '1min', value: 60 * 1000 },
  { text: '2min', value: 60 * 2000 },
  { text: '3min', value: 60 * 3000 },
  { text: '5min', value: 60 * 5000 },
  { text: '10min', value: 60 * 10000 },
  { text: '15min', value: 60 * 15000 },
  { text: '30min', value: 60 * 30000 },
  { text: '60min', value: 60 * 60000 },
];

const warnThreshold = 10000;
const errorThreshold = 5000;

@Component
export default class Timer extends Vue {
  @Prop({ type: Number, default: null })
  readonly value!: number;

  @Prop({ type: Array as () => TimerValues, default: () => defaultValues })
  readonly values!: TimerValues;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hidden!: boolean;

  @Watch('value')
  onValueChanged(newValue, oldValue) {
    //console.log('New timer value', newValue, oldValue);
    this.startTime = newValue;
    this.currentTime = newValue;
    if (newValue) {
      this.start();
    }
  }

  startTime = this.$props.value as number | null;
  currentTime = this.$props.value as number | null;
  timerId = null as number | null;
  color = 'default';
  sound = true;

  get time(): string | null {
    if (this.startTime === null || this.currentTime === null) return null;
    return formatDuration(this.currentTime, 'milliseconds', 'mm:ss');
  }

  created() {
    this.currentTime = this.startTime = null;
  }

  setTime(time: number) {
    this.currentTime = this.startTime = time;
    this.$emit('change', null);
    this.$emit('change', time);
    this.start();
  }

  start() {
    this.stop();
    if (this.currentTime === null) {
      this.color = 'default';
      return;
    }
    this.timerId = setInterval(this.tick, 1000);
    this.color = 'success';
  }
  stop() {
    if (this.timerId) clearInterval(this.timerId);
    this.color = 'error';
  }
  reset() {
    this.currentTime = this.startTime;
  }

  tick() {
    if (this.currentTime === null) return;
    if (this.currentTime <= 0) {
      this.stop();
      this.playSound('/sounds/bell.mp3');
      //this.playSound('/sounds/buzzer.mp3');
    } else {
      this.currentTime -= 1000;
      if (this.currentTime <= errorThreshold) {
        this.color = 'error';
        this.playSound('/sounds/tick.mp3');
      } else if (this.currentTime <= warnThreshold) {
        this.color = 'warning';
      }
    }
  }

  playSound(file: string) {
    if (this.sound) {
      const audio = new Audio(file);
      audio.play();
    }
  }

  beforeDestroy() {
    this.stop();
  }
}
