



























import { Vue, Component } from 'vue-property-decorator';
import { downloadJson } from '@/utils';
import { RetrospectiveSession, User } from '../models';
import { retrospectiveService } from '../services';
import Sessions from '../components/Sessions.vue';

@Component({ components: { Sessions } })
export default class Dashboard extends Vue {
  config = {};

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get tiles(): boolean {
    return this.$store.getters['retro/tiles'];
  }
  get viewIcon(): string {
    return this.tiles ? 'mdi-view-list' : 'mdi-view-grid';
  }

  created() {
    // Event listeners
    this.$bus.$off('retro-clone').$on('retro-clone', this.onRetrospectiveClone);
    this.$bus.$off('retro-export').$on('retro-export', this.onRetrospectiveExport);
    this.$bus.$off('retro-delete').$on('retro-delete', this.onRetrospectiveDelete);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Retro', '/retro');
    document.title = 'AgileMate Retrospectives';
    //this.$analytics.setCurrentScreen(document.title);
    this.$analytics.logEvent('retro-dashboard');
    this.$bind('config', retrospectiveService.getConfigRef());
  }

  toggleView() {
    this.$store.dispatch('retro/toggleTiles');
  }

  // Event handlers
  async onRetrospectiveClone(session: RetrospectiveSession) {
    try {
      await retrospectiveService.cloneSession(session, this.user);
      this.$bus.$emit('snackbar-notify', 'Retrospective successfully cloned', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onRetrospectiveExport(session: RetrospectiveSession) {
    try {
      downloadJson(session, session.id);
      this.$bus.$emit('snackbar-notify', 'Retrospective successfully exported', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onRetrospectiveDelete(session: RetrospectiveSession) {
    try {
      await retrospectiveService.deleteSession(session.id);
      this.$bus.$emit('snackbar-notify', 'Retrospective successfully deleted', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
