var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value.length)?_c('v-card',{staticClass:"pa-4",style:({ color: _vm.theme.colors.text }),attrs:{"color":_vm.theme.colors.background}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6"},[_vm._v("Actions")])]),_vm._l((_vm.value),function(action,idx){return _c('v-col',{key:idx,staticClass:"d-flex align-center text-body-2",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"elevation-2 mr-2",attrs:{"color":"white","size":"24"}},'v-avatar',attrs,false),Object.assign({}, tooltip, menu)),[(action.assignedTo)?_c('v-img',{attrs:{"src":action.assignedTo.avatar}}):_vm._e()],1)]}}],null,true)},[(action.assignedTo)?_c('div',{staticClass:"text-caption"},[_vm._v(" Action item assigned to "+_vm._s(action.assignedTo.username)+" "),_c('br'),_vm._v(" on "+_vm._s(_vm.formatDate(action.updatedOn, 'DD-MMM-YYYY HH:mm:ss'))+" ")]):_c('div',{staticClass:"text-caption"},[_vm._v("Action item unassigned")])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.participants),function(participant){return _c('v-list-item',{key:participant.id,attrs:{"dense":""},on:{"click":function($event){return _vm.setAssignee(action, participant)}}},[_c('v-list-item-avatar',{staticClass:"elevation-2",attrs:{"size":"24","color":"white"}},[_c('v-img',{attrs:{"src":participant.avatar}})],1),_c('v-list-item-title',[_vm._v(_vm._s(participant.username))])],1)}),1)],1),_c('v-checkbox',{staticClass:"ma-0",attrs:{"input-value":action.completedOn,"dense":"","color":"success","hide-details":"","label":action.title,"readonly":_vm.readonly},on:{"click":function($event){return _vm.toggleCheckbox(action)}}}),_c('v-menu',{attrs:{"offset-x":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1 ml-2",attrs:{"x-small":"","dark":"","outlined":"","color":_vm.getDueDateColor(action)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm.formatDate(action.dueOn) || 'No due date')+" ")],1)]}}],null,true)},[_c('v-date-picker',{attrs:{"dense":"","first-day-of-week":"1","show-week":"","value":_vm.formatDate(action.dueOn, 'YYYY-MM-DD')},on:{"change":function($event){return _vm.setDueDate(action, $event)}}})],1),_c('v-menu',{attrs:{"offset-x":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1 ml-2",attrs:{"x-small":"","dark":"","outlined":"","color":_vm.getAssigneeColor(action)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account")]),(action.assignedTo)?[(0)?_c('v-img',{attrs:{"width":"16","height":"16","src":action.assignedTo.avatar}}):_vm._e(),_vm._v(" "+_vm._s(action.assignedTo.username)+" ")]:[_vm._v(" Unassigned ")]],2)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.participants),function(participant){return _c('v-list-item',{key:participant.id,attrs:{"dense":""},on:{"click":function($event){return _vm.setAssignee(action, participant)}}},[_c('v-list-item-avatar',{staticClass:"elevation-2",attrs:{"size":"24","color":"white"}},[_c('v-img',{attrs:{"src":participant.avatar}})],1),_c('v-list-item-title',[_vm._v(_vm._s(participant.username))])],1)}),1)],1)],1)})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }