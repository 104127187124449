





































import { Vue, Component, Prop } from 'vue-property-decorator';
import { RetrospectiveThemes } from '../models';
import { defaultThemes } from '../defaults';

@Component
export default class ThemeSelector extends Vue {
  @Prop({ type: String, default: null })
  readonly value!: string;

  @Prop({ type: Object as () => RetrospectiveThemes, default: () => defaultThemes })
  readonly themes!: RetrospectiveThemes;

  selectTheme(themeId: string) {
    this.$emit('input', themeId);
  }
}
