










import { Vue, Component } from 'vue-property-decorator';
import { RetrospectiveSession, RetrospectiveType, User } from '../models';
import { defaultRetrospective } from '../defaults';
import { retrospectiveService } from '../services';
import SessionBuilder from '../components/SessionBuilder.vue';

@Component({ components: { SessionBuilder } })
export default class CreateSession extends Vue {
  session: RetrospectiveSession = defaultRetrospective(this.user);

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  mounted() {
    this.$bus.$emit('title-change', 'Retro', '/retro');
    document.title = 'AgileMate New Retro';
    //this.$bus.$emit('loading-indicator', true);
  }

  async saveSession(session: RetrospectiveSession, type: RetrospectiveType) {
    try {
      session.createdOn = Date.now();
      session.owner = this.userId;
      session.board = await retrospectiveService.cloneBoard(session.id, type);
      await retrospectiveService.createSession(session);
      this.$bus.$emit('snackbar-notify', 'Retrospective successfully saved', 'success');
      this.$analytics.logEvent('retro-create', { retro_id: session.id, user_id: this.userId });
      this.$router.push({ name: 'retro' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
