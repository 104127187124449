





















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { RetrospectiveSettings } from '../models';

@Component
export default class Settings extends Vue {
  @Prop({ type: Object as () => RetrospectiveSettings, required: true })
  readonly value!: RetrospectiveSettings;

  toggleSetting(name: string, value: boolean) {
    this.$emit('change', name, value ? true : false);
  }
}
