













































import { Vue, Component, Prop } from 'vue-property-decorator';
import { RetrospectiveSessions } from '../models';
import { retrospectiveService } from '../services';
import SessionsList from './SessionsList.vue';

@Component({ components: { SessionsList } })
export default class Sessions extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly tiles!: boolean;

  @Prop({ type: Object, required: true })
  readonly config!: Object;

  draftSessions: RetrospectiveSessions = [];
  activeSessions: RetrospectiveSessions = [];
  closedSessions: RetrospectiveSessions = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  created() {
    this.$bind('draftSessions', retrospectiveService.getDraftSessions(this.userId).orderBy('createdOn', 'desc'));
    this.$bind('activeSessions', retrospectiveService.getActiveSessions(this.userId).orderBy('createdOn', 'desc'));
    this.$bind('closedSessions', retrospectiveService.getClosedSessions(this.userId).orderBy('createdOn', 'desc'));
  }
}
