



































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import {
  RetrospectiveSession,
  RetrospectiveParticipant,
  RetrospectiveColumn,
  RetrospectiveCards,
  RetrospectiveTheme,
} from '../models';
import { minColumnWidth, maxCardTitleLength, defaultCard } from '../defaults';
import Card from './Card.vue';

@Component({ components: { Card, draggable } })
export default class Column extends Vue {
  @Prop({ type: Object as () => RetrospectiveColumn, required: true })
  readonly value!: RetrospectiveColumn;

  @Prop({ type: Object as () => RetrospectiveSession, required: true })
  readonly session!: RetrospectiveSession;

  @Prop({ type: Array as () => RetrospectiveCards, required: true })
  readonly cards!: RetrospectiveCards;

  @Prop({ type: Object as () => RetrospectiveParticipant, required: true })
  readonly user!: RetrospectiveParticipant;

  @Prop({ type: Object as () => RetrospectiveTheme, required: true })
  readonly theme!: RetrospectiveTheme;

  @Prop({ type: Boolean, default: false })
  readonly edit!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  @Prop({ type: Number, default: 0 })
  readonly userVotes!: number;

  minColumnWidth = minColumnWidth;
  maxCardTitleLength = maxCardTitleLength;
  cardTitle = '';

  get isOwner(): boolean {
    return this.session.owner === this.user.id;
  }
  get order(): number {
    return this.session.order;
  }
  get filteredCards(): RetrospectiveCards {
    let cards = [] as RetrospectiveCards;
    if (this.value.cardIds) {
      this.value.cardIds.forEach((cardId) => {
        const card = this.cards.find((card) => card.id === cardId);
        if (card) cards.push(card);
      });
    }
    return cards;
  }
  set filteredCards(cards: RetrospectiveCards) {
    const cardIds = cards.map((card) => card.id);
    //console.log('Setter', this.value.id, cardIds);
    this.$bus.$emit('retro-column-update', this.value.id, { cardIds });
  }

  // Card events
  createCard(title: string) {
    const card = defaultCard(title, this.user);
    this.cardTitle = '';
    this.$bus.$emit('retro-card-create', card, this.value);
  }
}
