





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { RetrospectiveSessions } from '../models';
import SessionTile from './SessionTile.vue';
import SessionRow from './SessionRow.vue';

@Component({ components: { SessionTile, SessionRow } })
export default class SessionsList extends Vue {
  @Prop({ type: Array as () => RetrospectiveSessions, required: true })
  readonly sessions!: RetrospectiveSessions;

  @Prop({ type: Boolean, default: false })
  readonly tiles!: boolean;

  @Prop({ type: Object, required: true })
  readonly config!: Object;
}
