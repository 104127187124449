























import { Vue, Component, Prop } from 'vue-property-decorator';
import Column from './Column.vue';
import {
  RetrospectiveBoard,
  RetrospectiveCards,
  RetrospectiveColumns,
  RetrospectiveSession,
  RetrospectiveParticipant,
  RetrospectiveTheme,
} from '../models';

@Component({ components: { Column } })
export default class Board extends Vue {
  @Prop({ type: Object as () => RetrospectiveBoard, required: true })
  readonly value!: RetrospectiveBoard;

  @Prop({ type: Object as () => RetrospectiveSession, required: true })
  readonly session!: RetrospectiveSession;

  @Prop({ type: Array as () => RetrospectiveColumns, required: true })
  readonly columns!: RetrospectiveColumns;

  @Prop({ type: Array as () => RetrospectiveCards, required: true })
  readonly cards!: RetrospectiveCards;

  @Prop({ type: Object as () => RetrospectiveParticipant, required: true })
  readonly user!: RetrospectiveParticipant;

  @Prop({ type: Object as () => RetrospectiveTheme, required: true })
  readonly theme!: RetrospectiveTheme;

  @Prop({ type: Boolean, default: false })
  readonly edit!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  @Prop({ type: Number, default: 0 })
  readonly votes!: number;

  get filteredColumns(): RetrospectiveColumns {
    let cols = [] as RetrospectiveColumns;
    this.value.columnIds.forEach((columnId) => {
      const col = this.columns.find((column) => column.id === columnId);
      if (col) cols.push(col);
    });
    return cols;
  }
}
